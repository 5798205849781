import axios from "axios";
import { Buffer } from "buffer";
import zlib from "react-zlib-js";
import moment, { Moment } from "moment";

export const handleResponse = (res: any, isEncypted = false): any => {
  if (res?.status_code === 200 && !res.response_error) {
    return isEncypted ? decodeV1Response(res.data) : res.data;
  }
  throw new Error(res?.status_message || "Unknown error occurred");
};

export const handleCatch = (res: { response: { status: number; data: { status_message: any } } }) => {
  if (res.response.status === 401) {
    localStorage.clear();
    window.location.reload();
  }
  if (res?.response?.data?.status_message) {
    throw res.response.data.status_message;
  } else throw { error: "Something went wrong" };
};

export const getIP = async () => {
  try {
    const res = await axios.get(
      `https://api-bdc.net/data/ip-geolocation-full?key=bdc_655c0adebc9040d891bac02d6ea38dfd`
    );
    if (res && res.data && res.data.ip) return res.data.ip;
    else return "192.168.0.1";
  } catch (error) {
    // console.error('Error fetching data:', error);
    if (error) {
      return "192.168.0.1";
    }
    throw error; // Re-throw the error to propagate it further
  }
  // return "192.168.0.1"
};

const xorEncrypt = (text: string, key: string) => {
  let encrypted = "";
  for (let i = 0; i < text.length; i += 1) {
    encrypted += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
    // const charCodeText = text.charCodeAt(i);
    // const charCodeKey = key.charCodeAt(i % key.length);
    // const xorResult = (charCodeText | charCodeKey) & ~(charCodeText & charCodeKey);
    // encrypted += String.fromCharCode(xorResult);
  }
  return encrypted;
};

export const encodeV1Response = (response: any) => {
  try {
    const jsonString = JSON.stringify(response);
    const encryptedData = xorEncrypt(jsonString, process.env.REACT_APP_SECRET_KEY || "");
    const responseData = zlib.gzipSync(encryptedData).toString("base64");
    // const responseData = zlib.deflateSync(encryptedData).toString('base64');
    // const responseData = zlib.brotliCompressSync(jsonString).toString('base64');
    return { data: responseData };
  } catch (error) {
    throw "something went wrong";
  }
};

export const xorDecrypt = (encryptedText: string, key: string) => {
  let decrypted = "";
  for (let i = 0; i < encryptedText.length; i++) {
    decrypted += String.fromCharCode(encryptedText.charCodeAt(i) ^ key.charCodeAt(i % key.length));
  }
  return decrypted;
};

export const decodeV1Response = (
  encodeResponse: WithImplicitCoercion<string> | { [Symbol.toPrimitive](hint: "string"): string }
) => {
  const brCompressed = Buffer.from(encodeResponse, "base64");
  const decodeData = zlib.gunzipSync(brCompressed);
  const jsonString = decodeData.toString();
  const key = process.env.REACT_APP_SECRET_KEY;
  const decryptedData = xorDecrypt(jsonString, key || "");
  return JSON.parse(decryptedData);
};

export function convertQueryStringToObject(
  queryString: string | string[][] | Record<string, string> | URLSearchParams | undefined
) {
  const params = new URLSearchParams(queryString);
  const obj: any = {};

  params.forEach((value: any, key: any) => {
    // Check if the key already exists, indicating an array
    if (obj.hasOwnProperty(key)) {
      // If it's not already an array, make it an array
      if (!Array.isArray(obj[key])) {
        obj[key] = [obj[key]];
      }
      obj[key].push(value);
    } else {
      obj[key] = value;
    }
  });

  return obj;
}

export const parseUnixTimeStamp = (value: Moment | null) => {
  try {
    if (!value) {
      return "";
    }
    return moment(value).unix();
  } catch (error) {
    return "";
  }
};
